<template>
  <div class="step1 sec-padding">
    <v-container fluid class="pa-0">
      <v-row>
        <v-col cols="4" sm="3">
          <div class="serviceOptions">
            <p class="error--text">{{ validationMsg }}</p>
            <p
              class="atSalon font-bold"
              :class="active == 'atSalon' ? 'active' : ''"
              @click="makeActive('atSalon')"
            >
              <v-icon>mdi-check-outline</v-icon>
              بالصالون
            </p>
            <p
              class="atHome font-bold"
              @click="makeActive('atHome')"
              v-if="!salonOnly"
              :class="active == 'atHome' ? 'active' : ''"
            >
              <v-icon>mdi-check-outline</v-icon>

              بالمنزل
            </p>
          </div>
        </v-col>
        <v-col cols="8" sm="6">
          <div class="sessions" v-if="cartItems && cartItems.length > 0">
            <div
              class="
                head
                d-flex
                flex-column flex-md-row
                justify-space-between
                align-baseline
              "
            >
              <h2 class="main-color salonName">
                {{ cartItems[0].salon }}
              </h2>
              <p v-if="active == 'atHome'">
                {{ cartItems[0].home_service_fees }} ر.س مصاريف اضافية سوف تضاف
                عند طلب الخدمة بالمنزل
              </p>
            </div>
            <div class="sessions-content mt-8">
              <div
                class="session d-flex justify-space-between align-baseline mb-5"
                v-for="(item, index) in cartItems"
                :key="index"
              >
                <p class="font-bold" :class="item.dimmed ? 'notActive' : ''">
                  {{ item.session_name }}
                </p>
                <p
                  class="price second-color"
                  :class="item.dimmed ? 'notActive' : ''"
                >
                  {{ item.price }} ر.س
                </p>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="3" class="proceed-sec text-center">
          <slot></slot>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "step1",
  props: ["cartItems", "originalCartItems", "active", "step", "validationMsg"],
  data() {
    return {
      //   active: "",
      salonOnly: "notChecked",
    };
  },
  watch: {
    cartItems: {
      handler() {
        this.checkHomeAvailable();
      },
    },
    step: {
      handler() {
        this.checkHomeAvailable();
      },
    },
  },
  methods: {
    makeActive(optionVal) {
      this.$emit("updateData", optionVal);
      // let filterResult = [];
      this.$emit("makeActive", optionVal);
    },
    checkHomeAvailable() {
      if (this.cartItems.length > 0) {
        this.salonOnly = !this.cartItems.some((item) => {
          return item.available_at_home == true;
        });

        if (this.salonOnly) {
          setTimeout(() => {
            this.$emit("updateData", "atSalon");
          }, 100);
        }

        if (this.salonOnly == true) {
          this.$emit("updateData", "atSalon");
          console.log(this.salonOnly);
        }
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "../../assets/_variables.scss";
.step1 {
  padding-right: 50px !important;
  padding-left: 50px !important;
  .serviceOptions {
    border-left: 1px solid $main-border-color;
    height: 100%;
    .atSalon,
    .atHome {
      font-size: 1.2rem !important;
      color: $second-color;
      cursor: pointer;
    }
  }

  .sessions {
    .salonName {
      font-size: 2rem;
    }

    .sessions-content {
      .session {
        border-bottom: 1px solid $main-border-color;
        p.notActive {
          color: #c6c6c6 !important;
        }
        p {
          font-size: 1.1rem !important;
        }
      }
    }

    .proceed-sec {
    }
  }
}

.hidden {
  display: none !important;
}

@media (max-width: 600px) {
  .step1 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
}
</style>

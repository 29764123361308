<template>
  <div class="step2 sec-padding">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9">
          <v-row>
            <v-col>
              <h2
                class="main-color font-bold salon-name mb-4"
                v-if="cartItems.length > 0 && cartItems[0].salon"
              >
                {{ cartItems[0].salon }}
              </h2>
              <hr class="branch-hr" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pa-0 branches" cols="12" sm="9">
              <v-row class="mb-0">
                <v-col>
                  <p class="error--text">{{ validationMsg }}</p>
                  <h2 class="font-bold choose-branch my-2">اختر فرع</h2>
                </v-col>
              </v-row>
              <v-row
                class="branch mt-0"
                v-for="(branch, index) in branches"
                :key="index"
              >
                <v-col cols="12" sm="12" class="">
                  <div class="mb-0 d-flex align-baseline">
                    <v-btn
                      class="text-center branch-radio"
                      :class="active == branch.id ? 'active' : ''"
                      icon
                      @click="selectBranch(branch.id, branch)"
                    >
                      <v-icon>mdi-check-bold</v-icon>
                    </v-btn>

                    <p class="mb-0">
                      <span class="second-color font-bold"
                        >{{ branch.name }} -
                      </span>
                      <span>{{ branch.formatted_address }}</span>
                    </p>
                  </div>
                </v-col>
                <!-- <v-col cols="2" sm="1" class="">
                  <v-btn icon :to="`/branch?id=${branch.id}`">
                    <v-icon color="#46101f" large>mdi-map-marker</v-icon>
                  </v-btn>
                </v-col> -->
              </v-row>
            </v-col>
            <v-col class="pa-0" cols="12" sm="3">
              <v-card class="ma-4 salon-cart" height="230px" width="230px">
                <div class="img-div">
                  <img
                    v-if="salon && salon.name"
                    :src="salon.main_image"
                    height="230px"
                    width="230px"
                    alt=""
                  />
                </div>
                <div class="content" v-if="salon">
                  <p class="name my-2">{{ salon.name }}</p>
                  <!-- <v-icon color="#dea22c">mdi-hexagram</v-icon>
                    <v-icon color="#dea22c">mdi-hexagram</v-icon>
                    <v-icon color="#dea22c">mdi-hexagram</v-icon>
                    <v-icon>mdi-hexagram-outline</v-icon>
                    <v-icon>mdi-hexagram-outline</v-icon> -->
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3" class="proceed-sec">
          <staticCartMenu
            :cartItems="cartItems"
            :totalPrice="totalPrice"
            :taxIncluded="true"
            @deleteCartItem="deleteCartItem"
          >
            <template v-slot:branches v-if="cartItems.length > 0">
              <div class="branchInfo">
                <p class="main-color font-bold branch" v-if="selectedBranch">
                  فرع
                  {{ selectedBranch.name }}
                </p>
                <p class="main-color font-bold serviceType">
                  {{ activeService == "atHome" ? "بالمنزل" : "بالصالون" }}
                </p>

                <hr class="cart-hr" />
                <p class="total mb-3">الضريبة : {{ calculateVat() }} ر.س</p>

                <p
                  class="main-color font-bold"
                  v-if="activeService == 'atHome'"
                >
                  المصاريف الاضافية لطلب الخدمة بالمنزل:
                  {{ cartItems[0].home_service_fees }}
                  ر.س
                </p>
              </div>
            </template>
            <v-btn class="login-btn" large @click="proceedToReservation"
              >تاكيد الحجز

              <v-icon color="#a53860" class="cart-btn-icon"
                >mdi-chevron-left</v-icon
              >
            </v-btn>
          </staticCartMenu>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import staticCartMenu from "@/components/staticCartMenu.vue";

export default {
  name: "step2",
  props: [
    "cartItems",
    "originalCartItems",
    "activeService",
    "step",
    "totalPrice",
    "validationMsg",
  ],
  components: {
    staticCartMenu,
  },
  data() {
    return {
      branches: [],
      selectedBranchId: "",
      selectedBranch: "",
      active: "",
      salon: {},
    };
  },
  computed: {
    ...mapGetters(["base_url", "getGlobalVat"]),
  },
  watch: {
    cartItems: {
      handler() {
        this.getSalonBranches();
      },
    },
    // step: {
    //   handler() {
    //     this.checkHomeAvailable();
    //   },
    // },
  },
  methods: {
    calculateVat() {
      let vat = 0;
      this.cartItems.forEach((item) => {
        vat += (item.price * Number(this.getGlobalVat)) / 100;
      });

      return vat;
    },
    selectBranch(id, branch) {
      this.selectedBranchId = id;
      this.selectedBranch = branch;
      this.active = id;
      this.$emit("updateData", this.active, "");
    },
    getSalonBranches() {
      if (this.cartItems.length > 0) {
        this.$http
          .get(
            this.base_url + "/front/getBranchs/" + this.cartItems[0].salon_id,
            {}
          )
          .then((response) => {
            this.branches = response.data.data.branches;
            this.salon = response.data.data.salon;
            if (this.branches.length == 1) {
              this.active = this.branches[0].id;
              this.selectedBranchId = this.branches[0].id;
              this.selectedBranch = this.branches[0];
            }
          });
      }
    },
    proceedToReservation() {
      console.log("reservae");

      if (this.selectedBranchId) {
        if (this.cartItems.length > 0) {
          let url =
            "/reserve-web/" +
            this.selectedBranch.slug +
            "?serviceType=" +
            this.activeService +
            "&branch=" +
            this.selectedBranchId;
          this.$router.push(url);
        } else {
          let text = "سلة المشتريات فارغة";
          this.$emit("updateData", this.active, true, text);
        }
      } else {
        let text = "اختر فرع اولا";
        this.$emit("updateData", this.active, text);
      }
    },
    deleteCartItem(itemId) {
      this.$emit("deleteCartItem", itemId);
    },
  },
  mounted() {
    this.getSalonBranches();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";
.step2 {
  padding-right: 50px !important;
  padding-left: 50px !important;
  .cart-hr {
    margin: 15px 0;
  }
  .salon-name,
  .choose-branch {
    font-size: 2rem !important;
  }
  .branch-hr {
    border-top: 1px solid $main-border-color;
  }
}

.branchInfo {
  .branch,
  .serviceType {
    font-size: 1.2rem !important;
  }
}

.salon-cart {
  border-radius: 0 40px 0 0;

  .img-div {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    img {
      border-radius: 0 40px 0 0;
      object-fit: fill;
      width: 100%;
    }
  }
  .content {
    color: #ffffff;
    padding: 0 10px;
    position: absolute;
    z-index: 3;
    bottom: -16px;
    right: 10%;
    background-color: #a53860;
    width: 90%;
    border-radius: 2px 15px 2px 2px;
  }
}

@media (max-width: 600px) {
  .step2 {
    padding-right: 12px !important;
    padding-left: 12px !important;
    .salon-cart {
      margin-right: auto !important;
      margin-left: auto !important;
    }
  }
}
</style>

<template>
  <div class="selectSession">
    <pageHeader>
      <cartMenu
        ref="cartMenu"
        @parentUpdateCart="updateCart"
        :active="active"
      />
    </pageHeader>
    <step1
      :cartItems="cartItems"
      :originalCartItems="originalCartItems"
      :active="active"
      :step="step"
      @updateData="step1UpdateData"
      @makeActive="makeActive"
      :validationMsg="validationMsg"
      :class="step == 1 ? '' : 'hidden'"
    >
      <v-btn
        class="login-btn white--text font-bold"
        color="#a53860"
        large
        @click="proceedToReservation"
        >انتقل إلى الحجز

        <v-icon color="#a53860" class="cart-btn-icon">mdi-chevron-left</v-icon>
      </v-btn>
    </step1>
    <step2
      v-if="step == 2"
      :class="step == 2 ? '' : 'hidden'"
      :cartItems="cartItems"
      :originalCartItems="originalCartItems"
      :activeService="active"
      :step="step"
      :totalPrice="totalPrice"
      @deleteCartItem="deleteCartItem"
      @updateData="step2UpdateData"
      :validationMsg="validationMsg"
    >
    </step2>

    <v-snackbar v-model="snackbar" :top="true" :left="true" color="error">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          اغلاق
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import pageHeader from "@/components/pageHeader.vue";
import cartMenu from "@/components/cartMenu.vue";

import step1 from "@/components/reservation/step1.vue";
import step2 from "@/components/reservation/step2.vue";
import { mapGetters } from "vuex";

export default {
  name: "steps",
  components: { pageHeader, step1, step2, cartMenu },
  data() {
    return {
      snackbar: false,
      text: `حدد مكان الخدمة اولا`,
      step: 1,
      active: "",
      cartItems: [],
      originalCartItems: [],
      cartItemsIds: [],
      cartId: "",
      totalPrice: "",
      selectedBranch: "",
      validationMsg: "",
    };
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  watch: {
    "$route.query": {
      handler() {
        this.step = this.$route.query.step;
        if (this.step == 1) {
          this.cartItems = this.originalCartItems;
          this.totalPrice = this.originaltotalPrice;
          this.makeActive(this.active);
          this.$refs.cartMenu.updateCart(
            this.cartId,
            this.cartItems,
            this.totalPrice,
            this.cartItemsIds
          );
        }
      },
      deep: true,
    },
    // step: {
    //   handler() {
    //     this.checkHomeAvailable();
    //   },
    // },
  },
  methods: {
    makeActive(optionVal) {
      this.cartItems = this.originalCartItems;
      if (optionVal == "atHome") {
        this.cartItems.forEach((item) => {
          if (item.available_at_home) {
            item.dimmed = false;
          } else {
            item.dimmed = true;
          }
        });
      } else {
        this.cartItems.forEach((item) => {
          item.dimmed = false;
        });
      }

      this.validationMsg = "";
    },
    showCart() {
      if (localStorage.getItem("cartId")) {
        this.cartId = localStorage.getItem("cartId");
      }
      if (this.cartId) {
        this.$http
          .get(this.base_url + "/front/showCart?cartID=" + this.cartId, {})
          .then((response) => {
            let vat = response.data.data.vat;
            this.$store.commit("setVat", vat);

            this.cartId = response.data.data.cartID;
            this.cartItems = response.data.data.items;
            this.originalCartItems = response.data.data.items;
            this.originaltotalPrice = response.data.data.totalPrice;
            this.totalPrice = response.data.data.totalPrice;
            this.cartItemsIds = [];

            this.cartItems.forEach((item) => {
              this.cartItemsIds.push(item.id);
            });
          });
      }
    },
    filterCartItems() {
      if (this.active == "atHome") {
        this.cartItems = this.cartItems.filter((item) => {
          return item.available_at_home == true;
        });

        let total = 0;
        this.cartItems.forEach((item) => {
          total += item.price;
        });
        if (this.cartItems.length > 0) {
          this.totalPrice = total + Number(this.cartItems[0].home_service_fees);
        }

        if (this.cartItems.length == 0) {
          this.totalPrice = 0;
        }

        // refresh and move to first step
        if (this.cartItems.length == 0 && this.step == 2) {
          window.location.href = "/reserve/steps?step=1";
        }
      }
    },
    proceedToReservation() {
      if (this.active) {
        this.step = 2;
        this.filterCartItems();
        this.$router.push("?step=2");
      } else {
        this.validationMsg = "حدد مكان الخدمة اولا";
      }
    },
    step1UpdateData(serviceType) {
      this.active = serviceType;
    },
    step2UpdateData(branchId, validationMsg) {
      this.selectedBranch = branchId;
      this.validationMsg = validationMsg;
    },
    updateCart(cartId, cartItems, totalPrice, cartItemsIds) {
      this.cartId = cartId;
      this.cartItems = cartItems;
      this.originalCartItems = cartItems;
      this.totalPrice = totalPrice;
      this.cartItemsIds = cartItemsIds;
    },
    deleteCartItem(itemId) {
      this.$http
        .get(
          this.base_url +
            "/front/removeFromCart/" +
            itemId +
            "?cartID=" +
            this.cartId,
          {}
        )
        .then((response) => {
          this.cartItems = response.data.data.items;
          this.originalCartItems = response.data.data.items;
          this.totalPrice = response.data.data.totalPrice;
          this.cartItemsIds = [];

          this.filterCartItems();
          this.cartItems.forEach((item) => {
            this.cartItemsIds.push(item.id);
          });

          this.$refs.cartMenu.updateCart(
            this.cartId,
            this.cartItems,
            this.totalPrice,
            this.cartItemsIds
          );
          console.log("deleted");
        });
    },
  },
  mounted() {
    this.showCart();
    this.step = this.$route.query.step;
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/_variables.scss";

.cart-btn-icon {
  width: 25px;
  height: 25px;
  background-color: #ffffff;
  border-radius: 50%;
  margin-right: 10px;
}
</style>

<template>
  <div class="cart">
    <div class="cart-head text-center">
      <h3 class="main-color">السلة</h3>
      <hr class="cart-hr" />
      <p
        class="salon-name mb-8 main-color"
        v-if="cartItems.length > 0 && cartItems[0].salon"
      >
        <v-icon color="#ffffff" class="salon-icon">mdi-chevron-right</v-icon>
        <span>
          {{ cartItems[0].salon }}
        </span>
      </p>
    </div>
    <div class="cart-content mt-4" v-if="cartItems.length > 0">
      <div v-for="item in cartItems" :key="item.id">
        <v-row class="align-baseline">
          <v-col class="pa-0" cols="6">
            <div class="title main-black">
              <p class="mb-0">
                {{ item.session_name }}
              </p>
            </div>
          </v-col>
          <v-col class="pa-0" cols="3">
            <p class="price second-color mb-0">{{ item.price }} ر.س</p>
          </v-col>
          <v-col class="pa-0" cols="3">
            <p class="delete">
              <v-btn icon small @click="deleteCartItem(item.id)">
                <v-icon color="#46101f">mdi-delete</v-icon>
              </v-btn>
            </p>
          </v-col>
        </v-row>
        <hr class="cart-hr" />
      </div>
    </div>
    <!-- ====================== show branches if exist ================= -->
    <slot name="branches"></slot>

    <div class="cart-total mt-4" v-if="totalPrice">
      <p class="total mb-0">الاجمالى :</p>
      <p class="num">
        {{ taxIncluded ? totalPrice + calculateVat() : totalPrice }} ر.س
      </p>
    </div>

    <div class="booking text-center" v-if="cartItems.length > 0">
      <slot></slot>
    </div>
    <div v-else>
      <p class="text-center mt-4">السلة فارغة</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "staticCartMenu",
  props: ["cartItems", "totalPrice", "taxIncluded"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["base_url", "getGlobalVat"]),
  },
  methods: {
    calculateVat() {
      let vat = 0;
      this.cartItems.forEach((item) => {
        vat += (item.price * Number(this.getGlobalVat)) / 100;
      });

      return vat;
    },
    deleteCartItem(itemId) {
      this.$emit("deleteCartItem", itemId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables.scss";

.cart {
  width: 80%;
  margin: 0 auto;
  background-color: #f7f7f7;
  padding: 25px;
  border-radius: 0 0 0 40px;
  .cart-hr {
    margin: 12px 0 15px;
  }
  .cart-head {
    .salon-name {
      font-size: 1.25rem !important;
      font-weight: bold;
      .salon-icon {
        background-color: $main-color;
        width: 25px;
        height: 25px;
        border-radius: 50%;
      }
    }
    h3 {
      font-size: 2rem;
    }
    hr {
      width: 30%;
      margin: 0 auto;
    }
  }

  .salon-name {
    font-size: 14px;
  }
  .cart-total {
    .num {
      font-size: 2rem !important;
    }
  }
}

@media (max-width: 600px) {
  .cart {
    width: 100% !important;
  }
}
</style>
